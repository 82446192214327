<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  methods: {
    memorizarPagina(e) {
      if (window.location.pathname !== '/autenticacao') {//evita loop infinito na tela de autenticacao
        window.sessionStorage.url = window.location.pathname
      }

      e.preventDefault()
      // Chrome requires returnValue to be set.
      // event.returnValue = ""
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.memorizarPagina)
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.memorizarPagina)
  },
  created() {
    document.title = process.env.VUE_APP_NOME_SISTEMA + ' - Polícia Civil'
  }
}
</script>